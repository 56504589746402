<template>
  <section class="contactForm text-center mt-110 mb-150 py-5">
    <div class="container">
      <div class="row">
        <div class="col-6 py-lg-5">
          <form
            v-on:submit.prevent="handleSubmit"
            class="text-left text-primary"
          >
            <SmartForm
              v-bind:fields="fields"
              v-bind:validations="$v.form"
              v-bind:inputClasses="props.inputClasses"
            />
          </form>

          <b-button class="font-weight-bold btn w-100 bg-primary mt-20"
            >Send Message</b-button
          >
        </div>
        <div class="col-6 text-left py-lg-5">
          <h2 class="text-primary">Contactanos</h2>
          <p class="text-muted mt-3">
            Formulario de atencion
            <span class="text-secondary font-weight-light"> <span class="text-primary font-weight-bold">PetDr. plan</span> escuchamos tus quejas peticiones y sugerencias. comunicate con nosotros y buscaremos una solucion</span>
          </p>
          <div class="ml-3 mt-4">
            <div class="mt-2 row">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="
                  fea
                  icon-m-md
                  text-dark
                  mr-3
                  feather feather-mail
                  text-primary
                "
              >
                <path
                  d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
                ></path>
                <polyline points="22,6 12,13 2,6"></polyline>
              </svg>
              <div class="float-right ml-3">
                <span class="text-primary font-weight-bold"> Email </span>
                <br />
                <span class="text-secondary font-weight-light"
                  >servicioalcliente@petdr.do</span
                >
              </div>
            </div>
            <div class="mt-2 row mt-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="fea icon-m-md text-dark mr-3 feather feather-phone text-primary"
              >
                <path
                  d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"
                ></path>
              </svg>
              <div class="float-right ml-3">
                <span class="text-primary font-weight-bold"> Telefono </span>
                <br />
                <span class="text-secondary font-weight-light"
                  >+1 809-985-4527</span
                >
              </div>
            </div>
            <div class="mt-2 row mt-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="fea icon-m-md text-dark mr-3 feather feather-map-pin text-primary"
              >
                <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                <circle cx="12" cy="10" r="3"></circle>
              </svg>
              <div class="float-right ml-3">
                <span class="text-primary font-weight-bold"> Ubicación </span>
                <br />
                <span class="text-secondary font-weight-light"
                  >Av. Winston Churchill esq. Max Henríquez Ureña, Ens. Evaristo
                  Morales</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import formMixin from "@/mixins/formMixin";
import { validationMixin } from "vuelidate";
import { PETDR_CONTACT_FORM as defaultValidations } from "@/utils/validations";

export default {
  mixins: [validationMixin, formMixin],
  props: {
    props: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      defaultValidations,
    };
  },
  methods: {
    handleSubmit() {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) return;

      console.log("Ejecutar acción!");
    },
  },
};
</script>